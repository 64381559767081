import { createBrowserRouter } from 'react-router-dom';
import NotFound from './routes/NotFound';
import Root from './components/Root';
import Home from './routes/Home';
import Login from './routes/Login';
import Cafe24Confirm from './routes/Cafe24Confirm';
import Cafe24Request from './routes/Cafe24Request';
import Partners from './routes/Partners';
import Friends from './routes/Friends';
import SignUpList from './routes/SignUpList';
import Orders from './routes/Orders';
import Points from './routes/Points';
import Settings from './routes/Settings';

const Router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,              //루트 페이지    
        errorElement: <NotFound />,     //404 페이지
        children: [                     //자식 페이지
            {   
                path: '', 
                element: <Points />,
            },
            {   
                path: 'pointslist', 
                element: <Points />,
            },
            {   
                path: 'settings', 
                element: <Settings />,
            },
            {   
                path: 'friends', 
                element: <Friends />,
            },
            {   
                path: 'partners', 
                element: <Partners />,
            },
            {   
                path: 'signuplist', 
                element: <SignUpList />,
            },
            {   
                path: 'orderslist', 
                element: <Orders />,
            },
            {   
                path: 'login', 
                element: <Login />,
            },
            {   
                path: "cafe24confirm",
                element: <Cafe24Confirm />,
            },
            {   
                path: "cafe24request",
                element: <Cafe24Request />,
            },
        ],
    }
]);

export default Router;