import React from 'react';
import {
  Box,
  Heading,
    Button,
    FormControl,
    Input,
    FormLabel,
    Grid,
    InputGroup,
    InputLeftAddon,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useToast,
} from "@chakra-ui/react"
import { putUser_rewardRate, putUser_signPoint } from '../api';
import useProtectedPage from '../hook/ProtectedPage';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useUser from '../lib/useUser';


export default function Settings() {

    const { userLoading, user, isLoggedIn } = useUser();

    console.log(user);


    useProtectedPage();
    const toast = useToast();


    const {register, handleSubmit} = useForm();
    const queryClient = useQueryClient();
    const { mutate: mutateSignPoint, isLoading: isLoadingSignPoint } = useMutation(putUser_signPoint,{
        onSuccess: (data) => {
            console.log("data");
            console.log(data);
            toast({
                title: "추천인 신규가입 적립금 설정 완료",
                status: "success",
                duration: 9000,
                isClosable: true,
                position: "top-center",
            });
        },
        onError: (error) => {
            console.log("error.response");
            console.log(error.response.data);
            console.log(error.response.status);
            toast({
                title: "추천인 신규가입 적립금 설정 Error",
                status: "error",
                duration: 9000,
                isClosable: true,
                position: "bottom-right",
            });
        },
    });
    const { mutate: mutateRewardRate, isLoading: isLoadingRewardRate } = useMutation(putUser_rewardRate,{
        onSuccess: (data) => {
            console.log("data");
            console.log(data);
            toast({
                title: "구매 적립율 설정 설정 완료",
                status: "success",
                duration: 9000,
                isClosable: true,
                position: "top-center",
            });
        },
        onError: (error) => {
            console.log("error.response");
            console.log(error.response.data);
            console.log(error.response.status);
            toast({
                title: "구매 적립율 설정 설정 Error",
                status: "error",
                duration: 9000,
                isClosable: true,
                position: "bottom-right",
            });
        },
    });
    const onSubmit = (data) => {
        mutateSignPoint({"sign_Point": data.sign_Point});
        mutateRewardRate({"rewardRate": data.rewardRate});
        console.log({"sign_Point": data.sign_Point});
        console.log({"rewardRate": data.rewardRate});
    }

    if (userLoading || !user) {
        return <div>Loading...</div>;
    }

    return (
        <Box p={4} flex="1">
            <Heading my={'6'} fontSize={'md'}>● SETTINGS </Heading>
            <Box as='form' w='500px' minW='330px' m={'24'}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FormControl>
                        <FormLabel>추천인 신규가입 적립금 설정 :</FormLabel>
                        <InputGroup>
                            <InputLeftAddon children='적립 금액' />
                            <Input
                                defaultValue={user?.sign_Point}
                                required 
                                type={'number'}
                                {...register('sign_Point')}
                            />
                        </InputGroup>
                    </FormControl>
                    <FormControl mt={8} >
                        <FormLabel>구매 적립율 설정 :</FormLabel>
                        {user?.rewardRate?.map((item, index) => (
                            <React.Fragment key={index}>
                            <Grid templateColumns="3fr 2fr" mt={4} gap={6} key={index}>
                                <InputGroup>
                                    <InputLeftAddon children={`${index} : POINT`} />
                                    <Input 
                                        type='hidden'
                                        defaultValue={item?.id}
                                        {...register(`rewardRate[${index}].id`)}
                                    />
                                    <Input
                                        defaultValue={item?.point}
                                        required 
                                        type={'number'}
                                        {...register(`rewardRate[${index}].point`)}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <InputLeftAddon children='적립율 %' />
                                    <Input
                                        defaultValue={item?.percent}
                                        required 
                                        type={'number'}
                                        {...register(`rewardRate[${index}].percent`)}
                                    />
                                </InputGroup>
                            </Grid>     
                            </React.Fragment>
                        ))}
                        
                    </FormControl>
                    
                    <Button isLoading={isLoadingSignPoint && isLoadingRewardRate}  type='submit' mt={8} colorScheme='blue' width='100%'>설정하기</Button>
                </Box>
        </Box>
      );
}
                        


    /*
    ## user jSON Data
    {
    "id": 1,
    "rewardRate": [
        {
            "id": 13,
            "point": 0,
            "percent": 6
        },
        {
            "id": 14,
            "point": 10000,
            "percent": 10
        },
        {
            "id": 15,
            "point": 50000,
            "percent": 15
        },
        {
            "id": 16,
            "point": 100000,
            "percent": 20
        },
        {
            "id": 17,
            "point": 500000,
            "percent": 30
        },
        {
            "id": 18,
            "point": 1000000,
            "percent": 40
        }
    ],
    "password": "pbkdf2_sha256$600000$MPiTIH4c2LEZAScIea8qV3$Wh3nwAav320+aSjeDcwrHXRX9jAHONC6BodlTRwzbRQ=",
    "last_login": "2023-10-20T09:44:06.987917+09:00",
    "is_superuser": true,
    "username": "admin",
    "first_name": "",
    "last_name": "",
    "email": "",
    "is_staff": true,
    "is_active": true,
    "date_joined": "2023-10-17T15:35:21+09:00",
    "cafe24_id": null,
    "sign_Point": 3000,
    "groups": [],
    "user_permissions": []
}*/
