import { useNavigate } from 'react-router-dom';
import useUser from '../lib/useUser';
import { useEffect } from 'react';


export default function useProtectedPage() {

    const { userLoading, isLoggedIn } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if(!userLoading) {
            if(!isLoggedIn) {
                navigate("/login");
            }
        }

    }, [userLoading, isLoggedIn, navigate])

    return;
}