import React, { useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Text, Spinner
} from "@chakra-ui/react"
import { useQuery } from '@tanstack/react-query';
import { getPartnerDetail, getPartnersList } from '../api';
import useProtectedPage from '../hook/ProtectedPage';


export default function Partners() {

    useProtectedPage();
    const [selectedItem, setSelectedItem] = useState(null);

    const { data, isLoading } = useQuery(['partners'], getPartnersList);
    const count = data?.length;
    console.log(data);

    const { data: detailData } = useQuery(['partnerDetail', selectedItem?.id], getPartnerDetail, {
        enabled: selectedItem !== null,
      });

    return (
        <Box p={4} flex="1">
            <Heading my={'6'} fontSize={'md'}>● MEMBERS {'>'} PARTNERS</Heading>
            <Text fontWeight={'medium'} fontSize={'sm'} mb={2}>
                ※ 나의 추천인 링크를 통해 친구가 회원가입을 하면 나는 PARTNER 로 등록됩니다.
            </Text>
            <Table maxW={'container.md'} size={'sm'} variant='simple'>
                <Thead>
                <Tr bgColor={'gray.100'}>
                    <Th>회원 ID ({count})</Th>
                    <Th isNumeric>프랜즈 포인트</Th>
                    <Th isNumeric>포인트 적립 횟수</Th>
                    <Th isNumeric>추천한 친구</Th>
                    <Th isNumeric>구매한 친구</Th>
                    <Th>등록일</Th>
                </Tr>
                </Thead>
                <Tbody>
                    {data?.map((item) => (
                        <Tr 
                            key={item.id} 
                            _hover={{ background: '#f5f5f5' }}
                            onClick={() => setSelectedItem(item)}
                            cursor={'pointer'}

                        >
                            <Td fontWeight={'medium'}>{item.member_id}</Td>
                            <Td fontWeight={'medium'} isNumeric>{item.total_points.toLocaleString()} p</Td>
                            <Td isNumeric>{item.count_points.toLocaleString()}</Td>
                            <Td isNumeric>{item.friends_Count.toLocaleString()}</Td>
                            <Td isNumeric>{item.count_buy.toLocaleString()}</Td>
                            <Td>{item.created_at}</Td>

                        </Tr>
                    ))}
                
                {/* ...more rows */}
                <Modal scrollBehavior={'inside'} size={'6xl'} isOpen={selectedItem !== null} onClose={() => setSelectedItem(null)}>
                    <ModalOverlay />
                    <ModalContent>
                    <ModalHeader>{selectedItem?.member_id} 상세 정보</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <Text as='b'>프랜즈 포인트 : {selectedItem?.total_points.toLocaleString()} p</Text>
                        <Table maxW={'container.sm'} size={'sm'} variant='simple'>
                        <Thead>
                            <Tr bgColor={'gray.100'}>
                                <Th>ID ({count})</Th>
                                <Th>프랜즈 포인트</Th>
                                <Th>지급사유</Th>
                                <Th>지급 일자</Th>
                            </Tr>
                        </Thead>
                        
                        <Tbody>
                        {detailData !== null ? (
                            <>
                            {detailData?.points.slice().reverse().map((item, index) => (
                                <Tr 
                                    key={item.id} 
                                    _hover={{ background: '#f5f5f5' }}
                                >
                                    <Td fontWeight={'medium'}>{detailData.points.length - index}</Td>
                                    <Td fontWeight={'medium'}>{item.point?.toLocaleString()} p</Td>
                                    {(() => {
                                        switch (item.type) {
                                            case 'buy_reward':
                                                return <Td color={'green'}>친구 구매적립 ({item.buy_reward_at_fr})</Td>;
                                            case 'first_sign':
                                                return <Td color={'blue'}>추천인 신규가입 (
                                                        {item.first_sign_at_pa === undefined ? null : item.first_sign_at_pa}
                                                        {item.first_sign_at_fr === undefined ? null : item.first_sign_at_fr}
                                                    )</Td>;
                                            default:
                                                return '';
                                        }
                                    })()}
                                    <Td>{item.created_at}</Td>

                                </Tr>
                            ))}
                            </>        
                            
                        ) : (
                            <Text>Loading...</Text>
                        )}
                        </Tbody>
                        </Table>
                    </ModalBody>
                    </ModalContent>
                </Modal>
                </Tbody>
            </Table>
            {isLoading && (
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minH='250px' maxW='500px'>
                    <Spinner size='xl' />
                </Box>
            )}
        </Box>
      );
}
                        