import { Heading, Spinner, Text, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import useUser from '../lib/useUser';



export default function Cafe24Request() {

    const { user,  } = useUser()

    const userMallId = user?.cafe24_id;


    console.log(userMallId);

    const mall_id = userMallId;
    const client_id = 'xBTJmkydI1KslYSK3hYrvC';
    const redirect_uri = 'https://melaxin-reward.xyz/cafe24confirm';
    const scope = 'mall.read_application,mall.write_application,mall.read_product,mall.write_product,mall.read_category,mall.read_collection,mall.read_supply,mall.read_personal,mall.read_order,mall.read_community,mall.read_customer,mall.read_promotion,mall.read_privacy,mall.read_mileage,mall.write_mileage';

    useEffect(() => {
        //window.location.href =`https://${mall_id}.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}`;
        console.log(`https://${mall_id}.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}`);
    }, []);

    return(
        // 그라디언트 배경
        // <VStack bgGradient="linear(to-r, blue.200, pink.500)" minH="100vh" justifyContent={'center'}>
        <VStack minH="100vh" justifyContent={'center'}>
            <Heading>Processing Logon...</Heading>
            <Text>Don't go anywhere</Text>
            <Spinner mt={4} size='xl' />
        </VStack>
    );
    
}