import { Box, Button, Container, FormControl, Heading, Input, VStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { logIn } from '../api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';


 export default function Login() {

    const {register, handleSubmit, reset} = useForm();
    const queryClient = useQueryClient();
    const navigate = useNavigate();


    const mutation = useMutation(logIn,{
        onMutate: (data) => {},
        onSuccess: (data) => {
            console.log(data);
            queryClient.refetchQueries(["me"]);
            navigate('/');
        },
        onError: (error) => {},
    })

    const onSubmit = (data) => {
        mutation.mutate(data);
    }

     return (
         <Container>
            <VStack minH="100vh" justifyContent={'center'} mt={'-24'}>
                <Heading textAlign={'center'} mb={4}>LOGIN</Heading>
                <Box as='form' w='20%' minW='330px'
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FormControl>
                        <Input
                            required 
                            variant={'filled'} 
                            placeholder='Username' 
                            {...register('username', {
                                required: true
                            })}
                        />
                    </FormControl>
                    <FormControl>
                        <Input
                            required 
                            variant={'filled'} 
                            mt={4} pr='4.5rem' 
                            type={'password'} 
                            placeholder='Enter password' 
                            {...register('password',{
                                required: true
                            })}
                        />
                    </FormControl>
                    <Button isLoading={mutation.isLoading} type='submit' mt={6} colorScheme='blue' width='100%'>Login</Button>
                </Box>
            </VStack>
         </Container>
     )
 }