import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Text,
  Spinner,
} from "@chakra-ui/react"
import { useQuery } from '@tanstack/react-query';
import { getPointssList } from '../api';
import useProtectedPage from '../hook/ProtectedPage';


export default function Points() {

    useProtectedPage();

    const { data, isLoading } = useQuery(['ordersList'], getPointssList);
    const count = data?.length;
    
    console.log(data);

    return (
        <Box p={4} flex="1">
            <Heading my={'6'} fontSize={'md'}>● POINTS {'>'} POINTS AWARDED</Heading>
            <Text fontWeight={'medium'} fontSize={'sm'} mb={2}>
                ※ <b>FRIENDS POINTS</b> 는 적립금과 동일하게 <b>PARTNER</b> 회원에게만 누적됩니다. 적립금 사용에 따라 차감되지는 않습니다.
                <br/>※ <b>FRIENDS</b> 회원은 <b>FRIENDS POINTS</b> 가 누적되지 않습니다. 
            </Text>
            <Table size={'sm'} variant='simple' colorScheme='gray'>
                <Thead>
                <Tr bgColor={'gray.100'}>
                    <Th>ID ({count})</Th>
                    <Th isNumeric>포인트</Th>
                    <Th>지급사유</Th>
                    <Th>유저타입</Th>
                    <Th>파트너 ID</Th>
                    <Th>프랜드 ID</Th>
                    <Th>코멘트</Th>
                    <Th>지급 일자</Th>
                </Tr>
                </Thead>
                <Tbody>
                    {data?.map((item, index) => (
                        <Tr key={index} _hover={{ background: '#f5f5f5' }}>
                            <Td fontWeight={'bold'}>{index + 1}</Td>
                            <Td isNumeric fontWeight={'medium'} color={'red'}>
                                {item.point === 0 || item.point === undefined ? null : item.point.toLocaleString()}
                            </Td>
                            {(() => {
                                switch (item.type) {
                                    case 'buy_reward':
                                        return <Td color={'green'}>친구 구매적립 ({item.buy_reward_at_fr})</Td>;
                                    case 'first_sign':
                                        return <Td color={'blue'}>추천인 신규가입 (
                                                {item.first_sign_at_pa === undefined ? null : item.first_sign_at_pa}
                                                {item.first_sign_at_fr === undefined ? null : item.first_sign_at_fr}
                                            )</Td>;
                                    default:
                                        return '';
                                }
                            })()}

                            {(() => {
                                switch (item.kind) {
                                    case 'partner':
                                        return <Td color={''}>🐵 Partner</Td>;
                                    case 'friends':
                                        return <Td color={''}>🐹 Friends</Td>;
                                    default:
                                        return '';
                                }
                            })()}
                            <Td>{item.partner_id_at}</Td>
                            <Td>{item.friend_id_at}</Td>
                            <Td>{item.comment}</Td>
                            <Td>{item.created_at}</Td>
                        </Tr>
                    ))}
                
                {/* ...more rows */}
                </Tbody>
            </Table>
            {isLoading && (
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minH='250px' maxW='500px'>
                    <Spinner size='xl' />
                </Box>
            )}
        </Box>
      );
}
                        