
import { Box } from '@chakra-ui/react';
import Header from './header';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'


export default function Root() {

    return(
        <Box 
            h={'100vh'}
            // maxWidth={'container.xl'} 
            // mx={'auto'}
            // backgroundColor={'blackAlpha.50'}
        >
            <Header />
            <ReactQueryDevtools initialIsOpen={false} />
        </Box>
    )

}