import React, { useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Text,
  Button,
  useToast,
  Spinner,
} from "@chakra-ui/react"
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getSignUpList, postRewardPayment } from '../api';
import useProtectedPage from '../hook/ProtectedPage';
import { FaCheck } from 'react-icons/fa';


export default function SignUpList() {

    useProtectedPage();

    const { data, isLoading } = useQuery(['signUpList'], getSignUpList);
    const count = data?.length;

    const toast = useToast();
    const queryClient = useQueryClient();

    const [loadingButtonId, setLoadingButtonId] = useState(null);

    const mutation = useMutation(postRewardPayment,{
        onSuccess: ( data ) => {
            console.log(data);
            toast({
                title: '적립금 지급 완료',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            queryClient.refetchQueries(['signUpList']);
            setLoadingButtonId(null); // 로딩 상태 초기화
        },
        onError: (error) => {
            console.log(error);
            toast({
                title: '이미 프랜드로 등록된 회원입니다.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            queryClient.refetchQueries(['signUpList']);
            setLoadingButtonId(null); // 로딩 상태 초기화
        }
    });


    const handlePayReward = (itemId, friendId) => {
        mutation.mutate(friendId);
        setLoadingButtonId(itemId); // 로딩 상태를 해당 아이템 ID로 설정
        console.log('friend_id: ');
        console.log(friendId);
    };

    
    console.log(data);
    return (
        <Box p={4} flex="1">
            <Heading my={'6'} fontSize={'md'}>● MEMBERS {'>'} SIGNUP-LIST</Heading>
            <Text fontWeight={'medium'} fontSize={'sm'} mb={2}>
                ※ 추천인 링크를 통해 회원가입을 시도한 명단 입니다. 기존회원이거나 동일한 ID 등 적합하지않은 경우는 적립금 지급이 제외됩니다.  
            </Text>
            
            <Table maxW={'container.md'}  size={'sm'} variant='simple' >
                <Thead>
                <Tr bgColor={'gray.100'}>
                    <Th>파트너 (추천인) ID ({count})</Th>
                    <Th>프랜드 (신규가입) ID</Th>
                    <Th>적립금 지급 여부</Th>
                    <Th>등록일</Th>
                    <Th>갱신</Th>
                </Tr>
                </Thead>
                <Tbody>
                    {data?.map((item) => (
                        <Tr key={item.id} _hover={{ background: '#f5f5f5' }}>
                            <Td fontWeight={'medium'}>{item.recommender_id}</Td>
                            <Td>{item.friend_id}</Td>
                            <Td>{item.accept ? <FaCheck className="text-green-500" /> : null}</Td>
                            <Td>{item.created_at}</Td>
                            <Td>
                                {!item.accept ? (
                                    <>
                                        <Button 
                                            size={'xs'} 
                                            onClick={() => handlePayReward(item.id, item.friend_id)}
                                            isLoading={loadingButtonId === item.id}
                                        >적립금 지급</Button>
                                        
                                    </>
                                ): null}
                            </Td>
                        </Tr>
                    ))}
                
                {/* ...more rows */}
                </Tbody>
            </Table>
            {isLoading && (
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minH='250px' maxW='500px'>
                    <Spinner size='xl' />
                </Box>
            )}
        </Box>
      );
}
                        