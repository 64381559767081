import Cookie from 'js-cookie';
import axios from 'axios';

const inst = axios.create({
    baseURL: 
    process.env.NODE_ENV === "development"
        ? "http://127.0.0.1:8000/api/v1/"
        : "https://backend.melaxin-reward.xyz/api/v1/",
    withCredentials: true, // 쿠키를 전달받기 위해 필요
})

// GET /api/v1/users/me
export const getMe = () => {
    return inst.get('users/me', {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken"),
        }
    }).then((res) => res.data);
}


// POST /api/v1/users/login
export const logOut = () =>
inst.post('users/log-out', null, {
    headers: {
        "X-CSRFToken": Cookie.get("csrftoken"),
    }
}).then((res) => res.data);


// POST /api/v1/users/login
export const logIn = (data) =>
    inst.post('users/log-in', data, {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken"),
        }
    }).then((res) => res.data);


// POST api/v1/users/cafe24-login
export const cafe24ReqAccesToken = (code) =>
    inst.post(
            'cafe24token/req-accestoken', 
            { code },
            {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken"),
                }
            }
    ).then(res => res.status);


// GET /api/v1/members/partners-list
export const getPartnersList = () => 
    inst.get('members/partners-list').then(res => res.data);


// GET /api/v1/members/partners-list
export const getFriendsList = () => 
    inst.get('members/friends-list').then(res => res.data); 

// GET /api/v1/members/signup-list
export const getSignUpList = () => 
    inst.get('members/signup-list').then(res => res.data); 

// GET /api/v1/orders/list
export const getOrdersList = () => 
    inst.get('orders/list').then(res => res.data); 

// PUT /api/v1/users/putUser_signPoint
export const updateOrserStates = (data) =>
    inst.post(
        'orders/hook-states', 
        data, 
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken"),
            }
        }
    ).then((res) => res.data);

// GET /api/v1/points/list
export const getPointssList = () => 
    inst.get('points/list').then(res => res.data); 

// PUT /api/v1/users/putUser_signPoint
export const putUser_signPoint = (data) =>
    inst.put('users/sign-point', data, {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken"),
        }
    }).then((res) => res.data);

// PUT /api/v1/users/putUser_rewardRate
export const putUser_rewardRate = (data) =>
    inst.put('users/reward-rate', data, {
        headers: {
            "X-CSRFToken": Cookie.get("csrftoken"),
        }
    }).then((res) => res.data);

// GET /api/v1/members/partner-detail
export const getPartnerDetail = ({queryKey}) => 
    inst.get(`members/partner/${queryKey[1]}`).then(res => res.data);


// POST /api/v1/members/new-member-hook
export const postRewardPayment = (data) =>
    inst.post(
        'members/new-member-hook', 
        {"resource":{"member_id": data}}, 
        {
            headers: {
                "X-CSRFToken": Cookie.get("csrftoken"),
            }
        }).then((res) => res.data);