import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Text,
  Spinner,
} from "@chakra-ui/react"
import { useQuery } from '@tanstack/react-query';
import { getFriendsList } from '../api';
import useProtectedPage from '../hook/ProtectedPage';
import { FaCheck } from 'react-icons/fa';


export default function Friends() {

    useProtectedPage();

    const { data, isLoading } = useQuery(['friends'], getFriendsList);
    const count = data?.length;
    
    console.log(data);

    return (
        <Box p={4} flex="1">
            <Heading my={'6'} fontSize={'md'}>● MEMBERS {'>'} FRIENDS</Heading>
            <Text fontWeight={'medium'} fontSize={'sm'} mb={2}>
                ※ 나의 추천인 링크를 통해 친구가 회원가입을 하면 친구는 FRIENDS 로 등록됩니다.
            </Text>
            <Table maxW={'container.sm'}  size={'sm'} variant='simple' >
                <Thead>
                <Tr bgColor={'gray.100'}>
                    <Th>회원 ID ({count})</Th>
                    <Th isNumeric>총 구매금액</Th>
                    <Th>구매여부</Th>
                    <Th>추천인 ID</Th>
                    <Th>등록일</Th>
                </Tr>
                </Thead>
                <Tbody>
                    {data?.map((item) => (
                        <Tr key={item.id} _hover={{ background: '#f5f5f5' }}>
                            <Td fontWeight={'medium'}>{item.member_id}</Td>
                            <Td isNumeric fontWeight={'medium'}>
                                {item.total_amount ? '₩ ' : null}
                                {item.total_amount ? item.total_amount.toLocaleString() : null}
                            </Td>
                            <Td>{item.is_buy ? <FaCheck className="text-green-500" /> : null}</Td>
                            <Td>{item.my_friends}</Td>
                            <Td>{item.created_at}</Td>

                        </Tr>
                    ))}
                
                {/* ...more rows */}
                </Tbody>
            </Table>
            {isLoading && (
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minH='250px' maxW='500px'>
                    <Spinner size='xl' />
                </Box>
            )}
        </Box>
      );
}
                        