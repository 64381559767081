import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  HStack,
  Button,
  Text,
  Spinner,
} from "@chakra-ui/react"
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getOrdersList, updateOrserStates } from '../api';
import useProtectedPage from '../hook/ProtectedPage';
import { FaCheck } from 'react-icons/fa';
import useUser from '../lib/useUser';
import { useForm } from 'react-hook-form';


export default function Orders() {

    useProtectedPage();
    const {user} = useUser();
    const queryClient = useQueryClient();

    const {handleSubmit} = useForm();

    const mutation = useMutation(updateOrserStates,{
        onSuccess: (data) => {
            console.log(data);
            queryClient.refetchQueries(['ordersList']);

        },
        onError: (error) => {
            console.log(error);
        },
    });

    const onSubmit = (data) => {
        mutation.mutate({mall_id: user.cafe24_id,});
        console.log(data);
    }

    const { data, isLoading } = useQuery(['ordersList'], getOrdersList);
    const count = data?.length;
    
    console.log(data);

    return (
        <Box p={4} flex="1">
            <HStack as='form' justifyContent={'space-between'} onSubmit={handleSubmit(onSubmit)}>
                <Heading my={'6'} fontSize={'md'}>● ORDERS {'>'} FRIENDS ORDER-LIST</Heading>
                <Button isLoading={mutation.isLoading} type='submit' colorScheme='blue' size='sm'>업데이트</Button>
            </HStack>
            <Text fontWeight={'medium'} fontSize={'sm'} mb={2}>
                ※ FRIENDS 회원이 구매한 주문 목록 입니다. 주문상태가 <b>배송완료</b>가 되면 PARTNER 에게 적립율에 따라 적립급이 지급됩니다.
                <br/>※ 주문상태는 매일 20시에 업데이트 됩니다. 또한 업데이트 버튼을 누르면 즉시 반영이 됩니다.
            </Text>
            <Table size={'sm'} variant='simple'>
                <Thead>
                <Tr bgColor={'gray.100'}>
                    <Th>프랜드 ID ({count})</Th>
                    <Th>주문번호</Th>
                    <Th>주문일자</Th>
                    <Th>결제방법</Th>
                    <Th>결제 예정금액(원)</Th>
                    <Th>최종 결제금액(원)</Th>
                    <Th>주문상태</Th>
                    <Th>적립금 지급 여부</Th>
                    <Th>업데이트 일자</Th>
                </Tr>
                </Thead>
                <Tbody>
                    {data?.map((item) => (
                        <Tr key={item.id} _hover={{ background: '#f5f5f5' }}>
                            <Td fontWeight={'medium'}>{item.friend_id_at}</Td>
                            <Td>{item.order_id}</Td>
                            <Td>{item.order_date}</Td>
                            <Td>{item.payment_method}</Td>
                            <Td>
                                {item.total_amount_due === 0 || item.total_amount_due === undefined ? null : '₩ '}
                                {item.total_amount_due === 0 || item.total_amount_due === undefined ? null : item.total_amount_due.toLocaleString()}
                            </Td>
                            <Td>
                                {item.payment_amount === 0 || item.payment_amount === undefined ? null : '₩ '}
                                {item.payment_amount === 0 || item.payment_amount === undefined ? null : item.payment_amount.toLocaleString()}
                            </Td>
                            <Td>
                                {(() => {
                                    switch (item.shipping_status) {
                                    case 'F':
                                        return '배송전';
                                    case 'M':
                                        return '배송중';
                                    case 'T':
                                        return '배송완료';
                                    case 'W':
                                        return '배송보류';
                                    case 'X':
                                        return '발주전';
                                    default:
                                        return '';
                                    }
                                })()}
                            </Td>
                            <Td>{item.reward_action ? <FaCheck className="text-green-500" /> : null}</Td>
                            <Td>{item.updated_at}</Td>
                        </Tr>
                    ))}
                
                {/* ...more rows */}
                </Tbody>
            </Table>
            {isLoading && (
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minH='250px' maxW='500px'>
                    <Spinner size='xl' />
                </Box>
            )}
        </Box>
      );
}
                        