import { Avatar, Flex, Heading, Menu, MenuButton, MenuItem, MenuList, Text, VStack, useToast } from '@chakra-ui/react';
import { Box, Button, HStack, useColorMode } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import useUser from '../lib/useUser';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { logOut } from '../api';
import { ChevronRightIcon } from '@chakra-ui/icons';


export default function Header() {

    const { userLoading, user, isLoggedIn } = useUser();
    console.log(userLoading, user, isLoggedIn);

    //// 테마 모드
    /*
    const { colorMode, toggleColorMode } = useColorMode();
    const label = colorMode === 'dark' ? <FaSun /> : <FaMoon />;
    */


    const queryClient = useQueryClient();


    const toast = useToast();
    const mutation = useMutation(logOut,{
        onSuccess: () => {
            toast({
                title: 'Logged out',
                description: "Done!",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
            queryClient.refetchQueries(["me"]);
        },
        onError: (error) => {
            toast({
                title: error.response.data.error,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    })

    const onLogOut = () => {
        mutation.mutate();
    }

    return(
        <Box>
            <HStack justifyContent={'space-between'} px={{ base: 5, md: 10, lg: 20 }} py={5} borderBottomWidth={1} backgroundColor={'gray.800'}>
                <Link to="/">
                    {/* <FaUserFriends size={48} color='gray'/> */}
                    <Heading color={'white'} size={'md'}>추천인 적립금 시스템</Heading>
                </Link>
                <HStack spacing={3}>
                    {/*
                    <Button onClick={toggleColorMode} colorScheme='blue' aria-label="Search database" >
                        {label}
                    </Button>
                    */}
                    {
                    !userLoading ? (
                        !isLoggedIn ? (
                            <Link to="/login">
                                <Button>Log in</Button>
                            </Link>  
                            
                        ) : (
                            <Menu>
                                <Text color='white'>{user?.username} 님</Text>
                                <MenuButton>
                                    <Avatar name={user?.username} src={user?.avatar} />
                                </MenuButton>
                                <MenuList>
                                    {user?.is_host ? (
                                    <Link to='/rooms/upload'>
                                        <MenuItem>Ceate Room</MenuItem>
                                    </Link>
                                    ) : (null)}
                                    <MenuItem onClick={onLogOut}>LogOut</MenuItem>
                                </MenuList>
                            </Menu>
                        )
                    ) : (null)
                    }
                      
                </HStack>
            </HStack>
            {/* Left Menu */}
            <Flex>
                <Box width="200px" borderRight="1px solid #ccc" h={'auto'} minH={`calc(100vh - 89px)`} backgroundColor={'gray.100'}>
                    <VStack textAlign={'center'} alignItems={'flex-end'} py={4}>
                        <Heading fontSize={'sm'} as={Text} px={4} color={'blackAlpha.600'}>
                            SETTINGS
                        </Heading>
                        <Box w={'full'} px={4}>
                            <Link to='/settings'>
                                <Box textAlign={'right'} w={'full'} fontSize={'md'} fontWeight={'bold'} my={'2'}>적립금 설정 <ChevronRightIcon /></Box>
                            </Link>
                        </Box>
                    </VStack>
                    <VStack textAlign={'center'} alignItems={'flex-end'} py={4}>
                        <Heading fontSize={'sm'} as={Text} px={4} color={'blackAlpha.600'}>
                            POINTS
                        </Heading>
                        <Box w={'full'} px={4}>
                            <Link to='/pointslist'>
                                <Box textAlign={'right'} w={'full'} fontSize={'md'} fontWeight={'bold'} my={'2'}>포인트 지급목록<ChevronRightIcon /></Box>
                            </Link>
                        </Box>
                    </VStack>
                    <VStack textAlign={'center'} alignItems={'flex-end'} py={4}>
                        <Heading fontSize={'sm'} as={Text} px={4} color={'blackAlpha.600'}>
                            MEMBERS
                        </Heading>
                        <Box w={'full'} px={4}>
                            <Link to='/partners'>
                                <Box textAlign={'right'} w={'full'} fontSize={'md'} fontWeight={'bold'} my={'2'}>파트너스 <ChevronRightIcon /></Box>
                            </Link>
                            <Link to='/friends'>
                                <Box textAlign={'right'} w={'full'} fontSize={'md'} fontWeight={'bold'} my={'2'}>프랜즈 <ChevronRightIcon /></Box>
                            </Link>
                            <Link to='/signuplist'>
                                <Box textAlign={'right'} w={'full'} fontSize={'md'} fontWeight={'bold'} my={'1'}>추천가입 목록<ChevronRightIcon /></Box>
                            </Link>
                        </Box>
                    </VStack>
                    <VStack textAlign={'center'} alignItems={'flex-end'} py={4}>
                        <Heading fontSize={'sm'} as={Text} px={4} color={'blackAlpha.600'}>
                            ORDERS
                        </Heading>
                        <Box w={'full'} px={4}>
                            <Link to='/orderslist'>
                                <Box textAlign={'right'} w={'full'} fontSize={'md'} fontWeight={'bold'} my={'2'}>프랜즈 주문목록<ChevronRightIcon /></Box>
                            </Link>
                        </Box>
                    </VStack>
                    
                </Box>
                <Outlet />
            </Flex>
        </Box>
    )

}